import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of my goals in 2018 was about working whenever I felt like, wherever I was in the world.`}</p>
    <p>{`Making my own schedule. It is not just about working remotely.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2048/1*VoAr0ppZj6G1O9j9Qq8BJg.png",
        "alt": "Gitlens"
      }}></img></p>
    <p>{`Every person has their own life, their own context, their own schedule.
I love waking up at 4:00 a.m. and start working by 4:30 a.m.
Other people love to stay awake all night, wake up and start the day at 11:00 a.m.`}</p>
    <p>{`I love to meet my coworkers in the workplace and networking in the co-working space where I am at the moment.`}</p>
    <p>{`However, I need my space for my productivity. Sometimes, working from home or a coffee shop is the best decision for me.
Unfortunately, more often than not, the working place is an open working space where you can hear everything is happening around you (even if it is not relevant for your tasks). Great for your social part of the job, not so great for your focus.`}</p>
    <p>{`You need to find your own balance between social and productivity at your working place.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/5760/0*X9hkLXO-parnpABk",
        "alt": "Oslo"
      }}></img></p>
    <p>{`I am planning the life that I want to live. Making my own schedule and not allowing other people dictating when I need to sit on a chair for start / stop my workday.`}</p>
    <p>{`The freedom to make my own schedule and where to work is gold for me. One of the things that I value the most in my current professional life.`}</p>
    <p>{`That’s for me is one of the pillars for a rich life. And rich is not only about money…`}</p>
    <p>{`A life that you love. Your life. Your way. Everyday. 24/7/365!`}</p>
    <p>{`Where is your daily working location? What time do you start your working day?`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      